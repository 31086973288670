import React from 'react'
import { SectionContainer } from '../containers/section-container'
import { Header3 } from '@app/components/general/header'
import { ThemedImage } from '@app/components/general/themes/theme-image'
import { LazyMount } from '@app/components/lazy/lazymount'
import type { Translate } from 'next-translate'
import { Paragraph } from '../typo/paragraph'

export const MarketingDashboard = ({
  t,
  lang,
}: {
  t: Translate
  lang?: string
}) => {
  const ext = lang == 'ja' ? '-ja' : ''

  return (
    <SectionContainer>
      <div className='place-content-center place-items-center flex flex-col space-y-4'>
        <div className='text-center'>
          <Header3>{t('details-title')}</Header3>
          <Paragraph className='text-lg'>{t('details-about')}</Paragraph>
        </div>
        <LazyMount single threshold={0.05} className='py-10'>
          <ThemedImage
            width={1496}
            height={833}
            quality={100}
            className='rounded shadow-[-2px_-1px_12px_5px_rgba(0,0,0,0.25)] dark:shadow-[-2px_-1px_12px_5px_rgba(255,255,255,0.25)]'
            src={`/img/dashboard-example${ext}.png`}
            srcDark={`/img/dark_dashboard-example${ext}.png`}
            alt={t('dashboard-alt')}
          />
        </LazyMount>
      </div>
    </SectionContainer>
  )
}
