import type { Translate } from 'next-translate'
import React from 'react'

// todo: translate title
export const MarketingShapes = ({ t }: { t: Translate }) => {
  return (
    <svg
      width='245'
      height='247'
      viewBox='0 0 408 401'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>{t('intro-graph-title')}</title>
      <g clipPath='url(#clip0_2_136)'>
        <path
          d='M242.961 336.194C192.11 325.586 140.221 313.605 96.6543 285.318C53.0878 257.03 18.8397 208.874 21.2599 156.984C23.6801 105.094 72.9588 55.9331 123.982 65.6868C166.107 73.7349 194.208 114.947 234.5 129.642C265.001 140.763 299.743 135.644 329.888 147.689C382.299 168.628 402.172 241.54 373.81 290.338C345.447 339.136 285.816 345.129 242.961 336.194Z'
          className='fill-primary-750 dark:fill-secondary'
        />
        <path
          d='M352.548 273.532H59.848V93.7321'
          className='stroke-gray-400 dark:stroke-white'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M356.842 237.749H64.142'
          stroke='white'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeDasharray='0.1 6'
        />
        <path
          d='M356.842 201.965H64.142'
          stroke='white'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeDasharray='0.1 6'
        />
        <path
          d='M356.842 166.182H64.142'
          stroke='white'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeDasharray='0.1 6'
        />
        <path
          d='M356.842 130.398H64.142'
          stroke='white'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeDasharray='0.1 6'
        />
        <path
          opacity='0.3'
          d='M333.964 94.614H301.024C298.263 94.614 296.024 96.8526 296.024 99.614V247.062C296.024 249.823 298.263 252.062 301.024 252.062H333.964C336.725 252.062 338.964 249.823 338.964 247.062V99.614C338.964 96.8526 336.725 94.614 333.964 94.614Z'
          className='fill-primary-900 dark:fill-white'
        />
        <path
          d='M326.8 101.771H293.86C291.098 101.771 288.86 104.01 288.86 106.771V254.219C288.86 256.98 291.098 259.219 293.86 259.219H326.8C329.561 259.219 331.8 256.98 331.8 254.219V106.771C331.8 104.01 329.561 101.771 326.8 101.771Z'
          className='fill-primary-950 dark:fill-white'
          stroke='white'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          opacity='0.3'
          d='M268.097 59H235.157C232.396 59 230.157 61.2386 230.157 64V247.232C230.157 249.993 232.396 252.232 235.157 252.232H268.097C270.858 252.232 273.097 249.993 273.097 247.232V64C273.097 61.2386 270.858 59 268.097 59Z'
          className='fill-primary-900 dark:fill-white'
        />
        <path
          d='M260.94 66.156H228C225.239 66.156 223 68.3946 223 71.156V254.388C223 257.149 225.239 259.388 228 259.388H260.94C263.701 259.388 265.94 257.149 265.94 254.388V71.156C265.94 68.3946 263.701 66.156 260.94 66.156Z'
          className='fill-primary-900 dark:fill-white'
          stroke='white'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <g opacity='0.3' filter='url(#filter0_d_2_136)'>
          <path
            d='M140.733 137.555H107.793C105.032 137.555 102.793 139.794 102.793 142.555V247.063C102.793 249.824 105.032 252.063 107.793 252.063H140.733C143.494 252.063 145.733 249.824 145.733 247.063V142.555C145.733 139.794 143.494 137.555 140.733 137.555Z'
            className='fill-gray-50 dark:fill-white'
          />
        </g>
        <g filter='url(#filter1_d_2_136)'>
          <path
            d='M133.569 144.711H100.629C97.8675 144.711 95.629 146.95 95.629 149.711V254.219C95.629 256.98 97.8675 259.219 100.629 259.219H133.569C136.33 259.219 138.569 256.98 138.569 254.219V149.711C138.569 146.95 136.33 144.711 133.569 144.711Z'
            className='fill-primary-900 dark:fill-white'
          />
          <path
            d='M133.569 144.711H100.629C97.8675 144.711 95.629 146.95 95.629 149.711V254.219C95.629 256.98 97.8675 259.219 100.629 259.219H133.569C136.33 259.219 138.569 256.98 138.569 254.219V149.711C138.569 146.95 136.33 144.711 133.569 144.711Z'
            stroke='white'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
        <path
          d='M111.099 286.415H123.099'
          stroke='white'
          strokeLinecap='square'
          strokeLinejoin='round'
        />
        <path
          d='M175.517 286.415H187.517'
          stroke='white'
          strokeLinecap='square'
          strokeLinejoin='round'
        />
        <path
          d='M241.941 286.415H253.941'
          stroke='white'
          strokeLinecap='square'
          strokeLinejoin='round'
        />
        <path
          d='M304.33 286.415H316.33'
          stroke='white'
          strokeLinecap='square'
          strokeLinejoin='round'
        />
        <path d='M40.5 124L46.1292 133H34.8708L40.5 124Z' fill='#D9D9D9' />
        <path d='M40.5 160L46.1292 169H34.8708L40.5 160Z' fill='#D9D9D9' />
        <path d='M40.5 196L46.1292 205H34.8708L40.5 196Z' fill='#D9D9D9' />
        <path
          d='M40.5 232L46.1292 241H34.8708L40.5 232Z'
          className='fill-gray-400 dark:fill-[#D9D9D9]'
        />

        {process.env.NEXT_PUBLIC_LOGO ? (
          <svg
            x='163.719'
            y='229.601'
            width={36}
            height={36}
            viewBox='0 0 36 36'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            dangerouslySetInnerHTML={{
              __html: `<title>${t('common:logo')}</title>${
                process.env.NEXT_PUBLIC_LOGO
              }`,
            }}
          ></svg>
        ) : (
          <>
            <ellipse
              cx='183.719'
              cy='229.601'
              rx='25.1476'
              ry='24.5408'
              className='fill-gray-600 dark:fill-[#353333]'
            />
            <ellipse
              cx='182.177'
              cy='231.94'
              rx='25.1476'
              ry='24.5408'
              className='fill-gray-400 dark:fill-[#514D4D]'
            />
            <ellipse
              cx='181.177'
              cy='236.06'
              rx='25.1476'
              ry='24.5408'
              className='fill-primary-900 dark:fill-white'
              stroke='white'
              strokeLinecap='square'
              strokeLinejoin='round'
            />
            <rect
              x='182.749'
              y='242.195'
              width='15.7172'
              height='15.338'
              className='fill-white dark:fill-[#202020]'
            />
          </>
        )}
      </g>
      <defs>
        <filter
          id='filter0_d_2_136'
          x='98.793'
          y='137.555'
          width='50.94'
          height='122.508'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_2_136'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_2_136'
            result='shape'
          />
        </filter>
        <filter
          id='filter1_d_2_136'
          x='90.629'
          y='143.711'
          width='52.94'
          height='124.508'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_2_136'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_2_136'
            result='shape'
          />
        </filter>
        <filter
          id='filter2_i_2_136'
          x='182.749'
          y='242.195'
          width='15.7172'
          height='15.338'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect1_innerShadow_2_136'
          />
        </filter>
        <clipPath id='clip0_2_136'>
          <rect
            width='407.57'
            height='400.002'
            className='fill-primary-900 dark:fill-white'
          />
        </clipPath>
      </defs>
    </svg>
  )
}
