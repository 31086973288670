import { PropsWithChildren } from 'react'
import { SectionContainer } from '../containers/section-container'
import { Header } from '@app/components/general/header'
import { MarketingShapes } from '@app/components/svgs/shapes'
import { getStartedStyles, liveDemoStyles } from './styles'
import { routeParseAbsolute } from '@app/lib/router-handler'
import type { Translate } from 'next-translate'
import { classNames } from '@app/utils'
import { blueLink } from '../typo/link-style'
import Trans from 'next-translate/Trans'
import { Paragraph } from '../typo/paragraph'
// import { GrCheckmark, GrCopy } from 'react-icons/gr'

interface MarketingIntro {
  checker?: boolean
  t: Translate
  lang: string
}

const MarketingIntro = ({
  children,
  t,
  lang,
}: PropsWithChildren<MarketingIntro>) => {
  // const [copyConfirm, setCopyConfirm] = useState<boolean>(false)
  // const [hover, setOnHover] = useState<boolean>(false)

  // const copyText =
  //   (text: string) =>
  //   (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
  //     e?.preventDefault()
  //     setCopyConfirm(true)
  //     navigator.clipboard.writeText(text)
  //     setTimeout(() => {
  //       setCopyConfirm(false)
  //     }, 2500)
  //   }

  // const onMouseIn = () => setOnHover(true)
  // const onMouseOut = () => {
  //   setOnHover(false)
  //   if (copyConfirm) {
  //     setCopyConfirm(false)
  //   }
  // }

  return (
    <SectionContainer>
      <div className='md:flex place-items-center pb-0'>
        <div className={'md:flex-[0_2_70%] pb-4'}>
          <Trans
            i18nKey='home:header'
            components={[
              <Header
                className='sm:leading-tight font-medium md:font-medium'
                key={'h1'}
              />,
              <strong key={'h2'} />,
              <br key='h3' />,
            ]}
          />
          <div className='space-y-2'>
            <div className='flex gap-x-1.5 place-items-center'>
              <div className='rounded bg-gray-100 dark:bg-gray-800 px-2 text-sm'>
                {t('home:new')}
              </div>
              <div>
                <a
                  href={routeParseAbsolute('/visualizer', lang)}
                  className={classNames(blueLink, 'px-0')}
                >
                  {t('home:new-feature')}
                </a>
              </div>
            </div>
            <Paragraph className='text-lg'>{t('home:title-intro')}</Paragraph>
            <Paragraph className='py-1 text-lg'>
              <strong className='font-bold'>{t('home:title-details')}</strong>
              {t('home:title-details-ending')}
            </Paragraph>
          </div>
          {children}
        </div>
        <div
          className={
            'flex flex-1 md:flex-none place-content-center place-items-center md:px-8'
          }
        >
          <MarketingShapes t={t} />
        </div>
      </div>
      <div className='relative md:inline-block'>
        <div className='gap-2 py-2 flex flex-col md:flex-row place-items-center'>
          <a
            className={getStartedStyles}
            href={routeParseAbsolute('/register', lang)}
          >
            {t('common:get-started')}
          </a>
          <a
            className={liveDemoStyles}
            href={routeParseAbsolute('/demo', lang)}
          >
            {t('common:live-demo')}
          </a>
        </div>
        {/* {lang == 'en' ? (
          <div className='py-4 text-center flex gap-2 place-items-center place-content-center'>
            <button
              className='w-9 h-9 rounded-full hover:dark:bg-blue-800 hover:bg-blue-300 flex place-items-center place-content-center text-sm'
              onClick={copyText('npm install a11ywatch')}
              type={'button'}
              onMouseOver={onMouseIn}
              onMouseOut={onMouseOut}
            >
              <>
                <span className='sr-only'>{t('home:copy-npm')}</span>
                {copyConfirm ? (
                  <GrCheckmark className='grIcon text-sm' />
                ) : hover ? (
                  <GrCopy className='grIcon text-sm' />
                ) : (
                  <span className='text-sm'>$</span>
                )}
              </>
            </button>
            <code className='block'>npm install a11ywatch</code>
          </div>
        ) : null} */}
      </div>
    </SectionContainer>
  )
}

export { MarketingIntro }
