import React from 'react'
import { SectionContainer } from '../containers/section-container'

import {
  GrAccessibility,
  GrAggregate,
  GrCloud,
  GrCode,
  GrCompare,
  GrNotification,
  GrTip,
} from 'react-icons/gr'
import { Header2 } from '@app/components/general/header'
import { routeParseAbsolute } from '@app/lib/router-handler'
import type { Translate } from 'next-translate'
import { Paragraph } from '../typo/paragraph'
import { Link } from '../typo/link'
import { blueLink } from '../typo/link-style'
import { classNames } from '@app/utils'

type FeatureProps = {
  alternative?: boolean
  all?: boolean
  t: Translate
  lang: string
}

const RenderIcon = ({ index, ...props }: any): any =>
  React.createElement(
    (() => {
      let FeatureIcon

      switch (index) {
        case 0:
          FeatureIcon = GrAccessibility
          break
        case 1:
          FeatureIcon = GrNotification
          break
        case 2:
          FeatureIcon = GrTip
          break
        case 3:
          FeatureIcon = GrCloud
          break
        case 4:
          FeatureIcon = GrCode
          break
        case 5:
          FeatureIcon = GrCompare
          break
        case 6:
          FeatureIcon = GrAggregate
          break
        default:
          FeatureIcon = 'div'
          break
      }
      return FeatureIcon
    })(),
    props
  )

const FeatureItem = ({
  index,
  title,
  detail,
}: {
  index: number
  title: string
  detail: string
}) => (
  <li
    className={`py-3 flex flex-col flex-1 border-b border-dashed border-gray-400 sm:px-3 sm:border sm:rounded-sm`}
  >
    <div className='flex space-x-2 pb-4 items-center'>
      <div className='flex rounded-3xl border w-10 h-10 min-w-[2.5rem] place-items-center place-content-center'>
        <RenderIcon index={index} className={'grIcon'} />
      </div>
      <h4 className='text-lg'>{title}</h4>
    </div>
    <div className={'flex-1'} />
    <Paragraph>{detail}</Paragraph>
  </li>
)

const FeaturesSlimList = ({ alternative, t, lang }: FeatureProps) => {
  const featList: { title: string; detail: string }[] = t(
    'features-list:features',
    {},
    { returnObjects: true }
  )
  return (
    <SectionContainer
      spaceX={!alternative}
      className={alternative ? 'md:px-0' : ''}
    >
      <div className={classNames(alternative ? 'pb-2' : '', 'text-center')}>
        <Header2 className='text-gray-800 dark:text-gray-200'>
          {t('features-list:sub-title')}
        </Header2>
        <Paragraph className='text-lg'>
          {t('features-list:sub-title-details')}
        </Paragraph>
        <div className='pt-2 pb-3'>
          <h3 className='text-sm md:text-base font-medium sr-only'>
            {t('features-list:features-title')}
          </h3>
        </div>
      </div>
      <ul className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 list-none'>
        {featList
          .filter((_, i) => i < 6)
          .map(({ title, detail }, index: number) => (
            <FeatureItem
              index={index}
              key={index}
              title={title}
              detail={detail}
            />
          ))}
      </ul>
      <div className='flex py-5 place-content-center text-center'>
        <Link
          href={routeParseAbsolute('/features', lang)}
          shouldPrefetch={false}
          className={blueLink}
        >
          {t('common:discover-features')}
        </Link>
      </div>
    </SectionContainer>
  )
}

export { FeaturesSlimList }
