import { World } from '@app/components/marketing/world'
import { SectionContainer } from '../containers/section-container'
import { Header3 } from '@app/components/general/header'
import { Paragraph } from '../typo/paragraph'
import { LazyMount } from '@app/components/lazy/lazymount'
import { Translate } from 'next-translate'

export const MarketingWorld = ({ t }: { t: Translate }) => {
  return (
    <SectionContainer className='overflow-hidden'>
      <div className='md:pb-10 place-content-center place-items-center flex flex-row space-y-4 flex-wrap relative'>
        <div className='flex-1 text-center'>
          <Header3>{t('madefor')}</Header3>
          <Paragraph className='text-lg'>{t('madefor-d')}</Paragraph>
        </div>
        <LazyMount
          single
          minTopValue={-200}
          threshold={0.05}
          className='cursor-grab absolute top-60 md:top-28'
        >
          <World />
        </LazyMount>
        <span className='h-40 inline-block w-full'></span>
      </div>
    </SectionContainer>
  )
}
