import { ReactElement } from 'react'
import { SectionContainer } from '../containers/section-container'
import { Header3 } from '@app/components/general/header'
import { accessibilityCoverage } from '@app/lib/static/coverage'
import { Paragraph } from '../typo/paragraph'
import type { Translate } from 'next-translate'

export function MarketingIntroCoverage({
  t,
}: {
  t: Translate
}): ReactElement<any, any> | null {
  const coverageInfo = t('coverage-amount', { count: accessibilityCoverage })

  return (
    <SectionContainer>
      <div className='border-4 border-double dark:border-gray-600 pt-1 pb-4 px-6 rounded'>
        <Header3>{coverageInfo}</Header3>
        <Paragraph className='text-lg'>{t('coverage-details')}</Paragraph>
      </div>
    </SectionContainer>
  )
}
