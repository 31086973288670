import { Translate } from 'next-translate'
import React from 'react'

type Props = {
  className: string
  width: number
  height: number
  t: Translate
}

export const ArrowSvg = ({ className, width, height, t }: Props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0'
      y='0'
      width={width}
      height={height}
      className={className}
      version='1.1'
      viewBox='0 0 174 35.8'
      xmlSpace='preserve'
    >
      <title>{t('common:logo', { companyName: 'Arrow' })}</title>
      <g>
        <path
          d='M117.2,9.3c-1.7,0-3.1,1.3-3.1,2.9v17.1h-21l-2.6-4.7l-4.5-7.8V3.5c0-1.6-1.4-2.9-3.1-2.9H67.7 c-1.7,0-3.1,1.3-3.1,2.9v25.9h-2.9l-7.1-12.5V3.5c0-1.6-1.4-2.9-3.1-2.9H36.3c-1.7,0-3.1,1.3-3.1,2.9v25.9h-4.8L21.7,2.8 c-0.3-1.3-1.6-2.2-3-2.2h-8.3c-1.4,0-2.6,0.9-3,2.2L0.1,32.1c-0.4,1.6,0.6,3.2,2.3,3.5c0.1,0,0.2,0,0.3,0c0.1,0,0.3,0,0.4,0 c0.2,0,0.4,0,0.5,0c1.2-0.2,2.2-1,2.5-2.2l6.8-27.1h3.5L23,33c0.3,1.3,1.6,2.3,3,2.3h10.3c1.7,0,3.1-1.3,3.1-2.9V6.4h9.2v11.2 c0,0.5,0.1,1,0.4,1.4l8.3,14.7c0.5,0.9,1.6,1.5,2.7,1.5h7.8c1.7,0,3.1-1.3,3.1-2.9V6.4h9.2v11.2c0,0.5,0.1,1,0.4,1.4l8.3,14.7 c0.4,0.7,1.1,1.2,1.9,1.4c0.3,0.1,0.5,0.1,0.8,0.1h25.9c1.7,0,3.1-1.3,3.1-2.9v-20C120.3,10.6,118.9,9.3,117.2,9.3z'
          className='fill-black dark:fill-white dark:invert-0'
        />
        <path
          d='M171,0.1c-1.7-0.3-3.3,0.7-3.6,2.3l-5.5,27h-1.6L155,2.9c-0.3-1.4-1.5-2.4-3-2.4h-5.8c-1.5,0-2.7,1-3,2.4 l-5.4,26.4h-1.6l-5.4-26.4c-0.3-1.4-1.5-2.4-3-2.4h-2.5H98.6c-1.7,0-3.1,1.3-3.1,2.9v20c0,1.6,1.4,2.9,3.1,2.9 c1.7,0,3.1-1.3,3.1-2.9V6.4h23.6l0,0.1l5.3,26.4c0.3,1.4,1.5,2.4,3,2.4h6.7c1.5,0,2.7-1,3-2.4l5.4-26.4h0.8l5.4,26.4 c0.3,1.4,1.5,2.4,3,2.4h6.7c1.5,0,2.7-1,3-2.4L173,5.5V1.4C172.6,0.7,171.9,0.2,171,0.1z'
          className='fill-black dark:fill-white dark:invert-0'
        />
      </g>
    </svg>
  )
}
