import { ReactElement } from 'react'
import { accessibilityCoverage } from '@app/lib/static/coverage'
import { Paragraph } from '../typo/paragraph'
import type { Translate } from 'next-translate'

export function MarketingStats({
  t,
}: {
  t: Translate
}): ReactElement<any, any> | null {
  const stats: any[] = t(
    'stats',
    { count: accessibilityCoverage },
    { returnObjects: true }
  )

  return (
    <div className='py-6 bg-gray-100 dark:bg-gray-900 rounded'>
      <div className='flex flex-col md:flex-row gap-4 px-4 md:divide-x-2 dark:divide-gray-800'>
        {stats.map((d, i) => {
          return (
            <div
              className='flex-1 place-items-center place-content-center text-center'
              key={`item-${i}`}
            >
              <Paragraph className='text-3xl font-bold'>{d.title}</Paragraph>
              <span className='text-sm uppercase'>{d.details}</span>
            </div>
          )
        })}
      </div>
    </div>
  )
}
