import React from 'react'
import { SectionContainer } from '../containers/section-container'
import { HuluSvg } from '../svgs/brand-hulu'
import { ArrowSvg } from '../svgs/brand-arrow'
import { Header4 } from '@app/components/general/header'
import type { Translate } from 'next-translate'
import { ExpensifyBrand } from '../svgs/brand-expensify'

const props = {
  width: 231.68,
  height: 56.45,
  className: 'max-h-12',
}

// Logo box container
const BtnBox = ({ children }: { children: React.ReactElement }) => (
  <div className='flex justify-center py-8 px-8'>{children}</div>
)

export function MarketingTrustBy({ t }: { t: Translate }) {
  return (
    <SectionContainer>
      <Header4 className='text-center max-w-none font-bold' bold>
        {t('trusted-title')}
      </Header4>
      <div className='grid md:grid-cols-3 py-3'>
        <BtnBox>
          <HuluSvg {...props} t={t} />
        </BtnBox>
        <BtnBox>
          <ExpensifyBrand {...props} t={t} />
        </BtnBox>
        <BtnBox>
          <ArrowSvg {...props} t={t} />
        </BtnBox>
      </div>
    </SectionContainer>
  )
}
