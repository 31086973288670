import { Translate } from 'next-translate'
import React from 'react'

type Props = {
  className: string
  width: number
  height: number
  t: Translate
}

export const ExpensifyBrand = ({ className, width, height, t }: Props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0'
      y='0'
      width={width}
      height={height}
      className={className}
      version='1.1'
      viewBox='0 0 936.7 220'
      xmlSpace='preserve'
    >
      <title>{t('common:logo', { companyName: 'Expensify' })}</title>
      <g>
        <polygon
          className='fill-black dark:fill-white'
          points='0,168.2 103.9,168.2 103.9,137.6 38.2,137.6 38.2,109.1 91.1,109.1 91.1,77.7 38.2,77.7 38.2,50.8    103.9,50.8 103.9,20.1 0,20.1  '
        />
        <polygon
          className='fill-black dark:fill-white'
          points='233.4,52.8 187.6,52.8 172.4,80.6 156.4,52.8 109.3,52.8 147.7,108.6 106.8,168.2 152.6,168.2    170.8,137.2 188.9,168.2 235.9,168.2 195,108.6  '
        />
        <path
          className='fill-black dark:fill-white'
          d='M305.4,50L305.4,50c-12.6,0-23.6,5.1-31.8,15V52.8h-35.1v164.5H276v-57.7c6.8,7,17.1,11.4,29.2,11.4   c30.6,0,51.9-23.6,51.9-60.5S337,50,305.4,50z M297.2,140.4c-14,0-22.4-11-22.4-29.9s8.4-29.9,22.4-29.9s22.4,10.8,22.4,29.9   C319.7,129.7,311.3,140.4,297.2,140.4z'
        />
        <path
          className='fill-black dark:fill-white'
          d='M423,141.8c-11.4,0-20.1-6.6-22.2-23.4h79.7v-4.9c0-36-20.1-63.6-59.1-63.6c-27.6,0-59.4,15-59.4,60.5   c0,36.9,23.8,60.5,60.8,60.5c22,0,49.3-7.9,58-42.1h-37.9C441.2,135.3,436.3,141.8,423,141.8z M421.4,77.5c10.8,0,18,6.1,19.2,19.4   h-39C404.3,82.2,412.5,77.5,421.4,77.5z'
        />
        <path
          className='fill-black dark:fill-white'
          d='M553.4,50c-12.9,0-24.3,5.6-31.8,16.4V52.8h-35.1v115.5H524V104c0-15.4,7.9-22.4,18.2-22.4   c11,0,17.5,4.7,17.5,22.2v64.5h37.4V101C597.1,64.5,582.4,50,553.4,50z'
        />
        <path
          className='fill-black dark:fill-white'
          d='M733.6,0.3c-11.4,0-19.6,8.4-19.6,19.1s8.2,19.4,19.6,19.4c11.4,0,19.8-8.6,19.8-19.4   C753.4,8.7,745.3,0.3,733.6,0.3z'
        />
        <rect
          x='715.3'
          y='53'
          className='fill-black dark:fill-white'
          width='37.3'
          height='115.2'
        />
        <path
          className='fill-black dark:fill-white'
          d='M666.1,96.3L666.1,96.3l-9.3-2.1c-9.8-2.1-13.3-6.4-13.3-10.9c0-6.4,5.4-10.5,14-10.5c11,0,16.8,3.6,16.8,14.2   h32.2c-0.7-24.3-14.9-38.6-50.7-38.6c-30.9,0-49.3,11.5-49.3,37.2c0,20.3,13.7,29.5,45,36.6l9.3,2.1c9.8,2.1,13.3,6.7,13.3,11.2   c0,6.4-5.6,9.2-15.4,9.2c-12.9,0-18.7-4.7-20.3-15.3H602c2.1,27.8,21.5,41.7,55.4,41.7s53.8-12.5,53.8-38.2   C711.1,112.5,697.5,103.4,666.1,96.3z'
        />
        <path
          className='fill-black dark:fill-white'
          d='M898.6,53L898.6,53l-21,70.5L857.1,53h-48.4v-3.6c0-9.4,7-18.9,17.9-18.9h5.3V0h-7.7c-32,0.7-53,23.2-53,51.5   V53h-14v34h14v81.2h37.3V87h21l28,78.2l-4.7,12.1c-3.5,8.9-7.5,11.4-15.2,11.4c-4,0-7.9,0-13.5-1.6l-2.3,30.6   c5.6,1.6,12.6,2.3,21.5,2.3c22.4,0,34.1-9.6,45.3-39.2l48-127.8H898.6z'
        />
      </g>
    </svg>
  )
}
