import React from 'react'
import { companyName } from '@app/configs'
import { SectionContainer } from '../containers/section-container'
import type { Translate } from 'next-translate'

const sections: { id: string }[] = [
  {
    id: 'intro',
  },
  {
    id: 'clause',
  },
  {
    id: 'growth',
  },
  {
    id: 'ending',
  },
  {
    id: 'customers',
  },
  {
    id: 'support',
  },
]

// TODO: add company name access locales
export const MarketingBenefits = ({ t }: { t: Translate }) => {
  return (
    <SectionContainer className='px-0'>
      <h5 className='sr-only'>{`${companyName} ${t(
        'common:web-accessibility'
      )}`}</h5>
      <article className='grid md:grid-cols-3 gap-x-8 gap-y-4 rounded p-6'>
        <h6 className='sr-only'>{`${t('common:about')} ${companyName}`}</h6>
        {sections.map((section) => (
          <p key={section.id} className={'text-sm font-mono leading-7'}>
            {t(`about.${section.id}`, { companyName })}
          </p>
        ))}
      </article>
    </SectionContainer>
  )
}
