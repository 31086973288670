import dynamic from 'next/dynamic'

const Globe = dynamic(
  import('./globe').then((mod) => mod.Globby),
  { ssr: false }
)

export const World = () => {
  return <Globe />
}
